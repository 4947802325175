.services{
    padding: 10rem 0;
    /* border: 2px solid red; */
    background-color:#EAC60B ;
    /* margin-bottom: 10rem; */

}    
    
.services-container{
    /* border: 2px solid blue; */
    display: flex;
    justify-content: center;    
    align-items: center;   
    

}
.service-items{
    z-index: 1;
    /* border: 2px solid green; */
    text-align: center;
    margin-left: 1rem;
    background-color: white;
    height: 30rem;
    width: 29rem;
    
    
}

.service-items h1{
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    color: #EAC60B ;
    margin-bottom: 1rem;
    text-transform: uppercase;
}
.service-items p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    /* border: 2px solid black; */
    padding: 1.5rem;
    /* text-align: center; */
}

/* ********* Media Queries************ */
@media(max-width:1030px){
    .service-items p {
        
        line-height: 2rem;
        
        padding: 1rem;
        
    }
    
}
@media(max-width:800px){
    .services-container{
     flex-wrap: wrap;
    }
}
