.popular{
    /* border: 2px solid red; */
    padding: 10rem 0;
    /* height: 85vh; */
    background-image: url(../../public/icons/popular-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.popular-textarea{
    /* border: 2px solid orange; */
    

}
.popular-textarea h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 7rem;
    font-weight: lighter;
}
.popular-textarea p {
    /* border: 2px solid green; */
    font-size: 1.8rem;
    line-height: 3rem;
    width: 60%;
    margin-bottom: 3rem;
}

.popular-container{
    /* border: 2px solid blue; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    
    /* justify-content: space-around; */
    
    /* align-items: center; */
}

.popular-image{
    /* border: 2px solid green; */
    width: 35rem;
    height: 40rem;
    padding: 3rem;
    background-color: #F2F2F2;
    margin-right: 2rem;
}
.popular-image img {
    width: 100%;
    /* height: 60%; */
    /* height: 60%; */
    object-fit: cover;
    object-position: center;
    margin-bottom: 2rem;
    /* display: block;
    margin: auto; */
}
.popular-image h1 {
    
    color: #F1C50E;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-size: 2.3rem;
    margin-bottom: 2rem;
}
.popular-image p {
    font-size: 1.5rem;
    font-style: italic;
    line-height: 2rem;
}
/* ********* Media Queries*************** */

@media(max-width:800px){
    .popular-container{
     flex-wrap: wrap;
    }
}
@media(max-width:450px){
    .popular-textarea h1 {
     
        font-size: 6rem;
        margin-bottom: 2rem;
     
    }
}