.repair{
    /* border: 2px solid red; */
    padding: 10rem 0;
    /* height: 90vh; */
    background-color: #1A1A1A;
}
.repair-heading{
    /* border: 2px solid blue; */
    text-align: center;
    /* padding: 3rem; */
    
}
.repair-heading h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 5rem;
    color: white;
    font-weight: lighter;  
    margin-bottom: 2rem;  
}
.repair-heading p {
    color:rgb(153, 153, 153);
    font-size: 2rem;
    font-style: italic;
    margin-bottom: 5rem;
}
.repair-container{
    /* border: 2px solid green; */
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    /* justify-content: center; */
    /* align-items: center; */ 
    /* text-align: center; */
    
    

}
.repair-items{
    flex: 1 1 301px;
    /* border: 2px solid orange; */
    /* margin-right: 2rem;
    margin-left: 2rem; */
    margin-bottom: 6rem;
    text-align: center;
    
    /* max-width: 250px; */   
    

}
.repair-items h1 {
    font-size: 2rem;
    font-family: 'Oswald', sans-serif;
    color: #EAC60C;
    text-transform: uppercase;
    margin-bottom: 2rem;
}
.repair-items p{
    color: white;
    font-size: 1.8rem;
    font-style: italic;
    line-height: 3rem;
    /* border: 2px solid white; */
    display: block;
    margin: auto;
    width: 250px;
}

.btn-repair{
    
    /* position: absolute; */
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid #EAC60B;
    font-size: 1.5rem;
    padding: 1rem 3rem;
    text-align: center; 
    width: 20%;
    
    color:#EAC60B;
    /* color:#EAC60B;  */
    margin-top: 8rem;
    

    display: block;
    margin: auto;

}
.btn-repair:hover{
    color: #EAC60B;
    border: 2px solid black;
    transition: 0.5s;
}

@media(max-width:990px){
    .btn-repair{
    
        width: 30%;
    }
}
@media(max-width:700px){
    .btn-repair{
    
        width: 40%;
    }
}
@media(max-width:450px){
    .btn-repair{
    
        width: 50%;
    }
}