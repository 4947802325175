.help-showcase{
    /* border: 2px solid red; */
    padding: 10rem 0;
    /* height: 100vh; */
    background-image: url(../img/repair01.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.help {
/* border: 2px solid white; */
/* position: relative; */
/* display: flex; */
}
.help-content{
    /* border: 2px solid blue; */
    /* flex: 1 1 30rem; */
    justify-content: center;
    width: 44rem;
    height: 52rem;
    background-color: #EAC60B ;
    padding: 3.5rem;
    /* position:absolute; */
    margin-top: 7rem;
    max-width: 100%;
    
}
.help-content h2 {
    /* text-align: center; */
    font-size: 4rem;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 0.8rem;
}
.help-content h1 {
    font-size: 8rem;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight:500;
}

/* *****Media Queries******** */

@media(max-width:450px){
    .help-content h1 {
        font-size: 6rem;
     
    }
}